export const tableHeaderTextHistoryTable = [
  {
    styles: 'w-[8%] lg:pt-0',
    rowWid: 'w-[8%]',
    name: 'Job Id',
    label: 'job_id',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: true,
  },
  {
    styles: 'w-[12%]',
    rowWid: 'w-[12%]',
    name: 'To',
    label: 'to',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: false,
  },
  {
    styles: 'lg:pt-0 w-[15%]',
    rowWid: 'w-[15%]',
    name: 'Send Date',
    label: 'send_date',
    isFilter: true,
    isFilterDate: true,
    isOneLineRes: true,
  },
  {
    styles: 'lg:pt-0 w-[10%]',
    rowWid: 'w-[10%]',
    name: 'Send By',
    label: 'send_by',
    isFilter: true,
    isFilterDate: true,
    isOneLineRes: true,
  },
  {
    styles: 'w-[43%]',
    rowWid: 'w-[43%]',
    name: 'Content',
    label: 'content',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: false,
  },
  {
    styles: 'w-[12%] lg:pt-0',
    rowWid: 'w-[12%]',
    name: 'Status',
    label: 'Status',
    isFilter: false,
    isFilterDate: false,
    isOneLineRes: true,
  },
]