import React, { useState } from 'react'
import EmailHistoryTable from '../components/sent-email-text-history/email-history-table'
import TextHistoryTable from '../components/sent-email-text-history/text-history-table'
const SentEmailTextHistory = () => {

  const [isRefetchList, setIsRefetchList] = useState(true)
  return (
    <div className="px-20 py-10 md:px-10">
      <EmailHistoryTable  isRefetchList={isRefetchList} setIsRefetchList={setIsRefetchList}></EmailHistoryTable>
      <hr className="my-10 border-t-neutral-6" />
      <TextHistoryTable isRefetchList={isRefetchList} setIsRefetchList={setIsRefetchList} />
    </div>
  )
}

export default SentEmailTextHistory
