export const tableHeaderReceivedEmailHistoryTable = [
  {
    styles: 'w-[9%] lg:pt-0',
    rowWid: 'w-[9%]',
    name: 'Application Number',
    label: 'application_number',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: false,
  },
  {
    styles: 'w-[13%] lg:pt-0',
    rowWid: 'w-[13%]',
    name: 'Received At',  // Only show the name
    label: 'created_at',  // Date store in database
    isFilter: false,
    isFilterDate: false,
    isOneLineRes: false,
  },
  {
    styles: 'w-[14%] lg:pt-0',
    rowWid: 'w-[14%]',
    name: 'Receiver',
    label: 'receiver',
    isFilter: true,
    isFilterDate: true,
    isOneLineRes: false,
  },
  {
    styles: 'w-[15%] lg:pt-0',
    rowWid: 'w-[15%]',
    name: 'Subject',
    label: 'subject',
    isFilter: true,
    isFilterDate: true,
    isOneLineRes: false,
  },
  {
    styles: 'w-[26%] lg:pt-0',
    rowWid: 'w-[26%]',
    name: 'Content',
    label: 'content',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: false,
  },
  {
    styles: 'w-[14%] lg:pt-0 ',
    rowWid: 'w-[14%]',
    name: 'Carrier Bond Number',
    label: 'carrier_bond_number',
    isFilter: true,
    isFilterDate: true,
    isOneLineRes: false,
  },
  {
    styles: 'w-[9%] lg:pt-0',
    rowWid: 'w-[9%]',
    name: 'Is Generated Job',  
    label: 'is_generated_job',  
    isFilter: false,
    isFilterDate: false,
    isOneLineRes: false,
  },
]