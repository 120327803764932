import React, { useState } from 'react'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import ButtonBase from '../../../components/partials/button'
import { defaultCustomStyles } from '../../../constants/customStyles'
import { notify } from '../../../components/toastify'

type Props = {
  modalIsOpen?: boolean
  title: string
  confirmation: string
  action: string
  handleCloseDialog?: (val: boolean) => void
  handleAction?: () => void
  cancel?: string
}

const DialogBasic: React.FunctionComponent<Props> = ({
  modalIsOpen,
  title,
  confirmation,
  action,
  handleCloseDialog = () => null,
  handleAction = () => null,
  cancel,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onClickAction = async () => {
    try {
      setIsLoading(true)
      await handleAction()
    } catch (error) {
      notify('Something wrong')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={defaultCustomStyles}>
      <React.Fragment>
        <div className="flex justify-between">
          <div className="absolute right-4 top-4">
            <img
              src={iconTimes}
              alt=""
              className="cursor-pointer"
              onClick={() => handleCloseDialog(false)}
            />
          </div>
        </div>
        <div>
          <p className="mb-6 text-headline5 text-indicator-red text-center">{title}</p>
          <p className="mb-6 font-semibold text-body1 text-indicator-red text-center">
            {confirmation}
          </p>
          <div className="flex justify-between gap-3">
            <ButtonBase
              type="button"
              className="w-[180px] h-[52px] border-0 bg-primary-1 text-hairline1 min-w-min text-center text-neutral-8"
              onClick={() => handleCloseDialog(false)}
            >
              {cancel ?? 'Cancel'}
            </ButtonBase>
            {isLoading ? (
              <ButtonBase type="button" className="buttonload flex" disabled={true}>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Triggering...
              </ButtonBase>
            ) : (
              <ButtonBase
                type="reset"
                className="w-[180px] h-[52px] text-hairline1 bg-neutral-8 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4"
                onClick={onClickAction}
              >
                {action}
              </ButtonBase>
            )}
          </div>
        </div>
      </React.Fragment>
    </BaseDialog>
  )
}

export default DialogBasic
