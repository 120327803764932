import React, { memo, useEffect, useState } from 'react'
import { tableHeaderEmailHistoryTable } from './headers'
import Moment from 'react-moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { styleJsonPacket } from '../../../../../constants/common'
import { MESSAGES, STATUS } from '../../../../../constants/messages'
import IconViewEmailByAMS from '../../../../../assets/images/icon-email.svg'
import { useQuery } from '@apollo/client'
import { GET_EMAIL_HISTORY_QUERY } from '../../../../../queries/adminPortal'
import { notify } from '../../../../../components/toastify'
import classNames from 'classnames'
import DateFilterPicker from '../../../../developer-portal/table/activities-log/component/dateFilterPicker'
import SearchInput from '../../../../principal-account-management/components/searchInput'
import moment from 'moment'
import Loading from '../../../../../components/loading'
import ViewDetailContentItemDialog from '../../share/view-detail-content-log'

interface Props {
  setIsRefetchList: (refetchUser: boolean) => void
  isRefetchList: boolean
}

interface IHistoryEmailItem {
  jobId: number
  to: string
  send_date: string
  content: string
  status: boolean
  subject: string
}
interface IEmailFilterItem {
  dateFrom?: string
  dateTo?: string
  skip: number
  take: number
  searchValue?: string
}

const EmailHistoryTable: React.FC<Props> = () => {
  const [hasMoreEmailData, setHasMoreEmailData] = useState<boolean>(true)
  const [isOpenModalViewDetailEmail, setIsOpenModalViewDetailJob] = useState<boolean>(false)
  const [currentEmailItemSelected, setCurrentEmailItemSelected] = useState<IHistoryEmailItem>()
  const [listEmailItemLog, setListEmailItemLog] = useState<IHistoryEmailItem[]>([])
  const [searchObject, setSearchObject] = useState<IEmailFilterItem>()
  const [minDateFilter, setMinDateFilter] = useState<Date>()
  const [maxDateFilter, setMaxDateFilter] = useState<Date>()
  const [isFocusSearchJobIdOrEmail, setIsFocusSearchJobIdOrEmail] = useState<boolean>(false)
  const fetchMoreDataForEmailHistory = async () => {
    try {
      const response = await fetchMoreForGetEmailLog({
        variables: {
          filter: {
            ...searchObject,
            skip: listEmailItemLog.length,
            take: 20,
          },
        },
      })
      const data = response.data.getEmailHistory
      if (data.length > 0) {
        setHasMoreEmailData(data.length === 20)
        const dataState = [...listEmailItemLog, ...data]
        setTimeout(() => {
          setListEmailItemLog(dataState)
        }, 1000)
      } else {
        setHasMoreEmailData(false)
        return
      }
    } catch (error) {
      notify(MESSAGES.ERROR.E_TRY_AGAIN, STATUS.ERROR)
    }
  }

  const {
    data: getEmailHistoriesFromQuery,
    loading: getEmailHistoryLoading,
    refetch: getEmailLogListRefetch,
    fetchMore: fetchMoreForGetEmailLog,
  } = useQuery(GET_EMAIL_HISTORY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: searchObject,
    },
  })

  const handleCloseEmailInfoDialog = () => {
    setIsOpenModalViewDetailJob(false)
  }

  const onHandleClickViewDetailJobAction = () => {
    setIsOpenModalViewDetailJob(true)
  }
  const startDateChange = (value: Date) => {
    setMinDateFilter(value)
    const dateFrom = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateFrom, take: 20, skip: 0 }
    })
  }
  const endDateChange = (value: Date) => {
    setMaxDateFilter(value)
    const dateTo = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateTo, take: 20, skip: 0 }
    })
  }
  const handleSearchJobIdAndEmail = (value: string) => {
    setSearchObject((state) => {
      return { ...state, searchValue: value.trim(), take: 20, skip: 0 }
    })
  }
  // set data for list activities log
  useEffect(() => {
    setListEmailItemLog(getEmailHistoriesFromQuery?.getEmailHistory)
  }, [getEmailHistoriesFromQuery])
  useEffect(() => {
    if (searchObject) {
      setHasMoreEmailData(true)
      getEmailLogListRefetch()
    }
  }, [searchObject])
  return (
    <div>
      <div className="flex justify-between mb-3">
        <p className="pt-2 text-headline5A text-neutral-1">Email History</p>
      </div>
      <div className="flex w-full mb-5 grid grid-cols-3 gap-2">
        <DateFilterPicker setCallBackDateChange={startDateChange} maxDate={maxDateFilter} />
        <DateFilterPicker setCallBackDateChange={endDateChange} minDate={minDateFilter} />
        <div
          className={classNames('rounded-xl border-[2px]  w-full', {
            'border-neutral-3': isFocusSearchJobIdOrEmail,
            'border-neutral-5': !isFocusSearchJobIdOrEmail,
          })}
        >
          <SearchInput
            classInput="border-0 w-full"
            setIsFocus={setIsFocusSearchJobIdOrEmail}
            handleSearch={handleSearchJobIdAndEmail}
            placehoderText="Search box jobId or email"
            isHandleSearch={false}
          />
        </div>
      </div>
      <table className="w-full text-left">
        <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
          <tr>
            {tableHeaderEmailHistoryTable.map((item: any, idx) => (
              <th key={idx} className={`font-semibold py-5 pl-6 ${item.styles}`}>
                <div className={`flex`}>
                  <span className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}>{item.name}</span>
                  <div className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}></div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
      </table>
      {getEmailHistoryLoading && <Loading className="relative py-6" height={30} width={30} />}

      {listEmailItemLog?.length === 0 && !getEmailHistoryLoading ? (
        <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
      ) : (
        Boolean(
          listEmailItemLog !== null && listEmailItemLog?.length > 0 && !getEmailHistoryLoading,
        ) && (
          <InfiniteScroll
            dataLength={listEmailItemLog?.length}
            next={fetchMoreDataForEmailHistory}
            hasMore={hasMoreEmailData}
            height={'max-content'}
            style={{ maxHeight: 980 }}
            loader={''}
            endMessage={''}
            className="cus-scrollbar"
          >
            {listEmailItemLog?.map((itemAMS: IHistoryEmailItem, index: number) => (
              <table key={index} className="cursor-pointer w-full">
                <tbody>
                  <tr className="h-[72px] w-full hover:bg-neutral-7 pt-5">
                    <td
                      className={` ${tableHeaderEmailHistoryTable[0].rowWid} py-5 break-all pl-6 md:pl-1 align-top md:pl-6`}
                    >
                      <span>{itemAMS.jobId || 'NA'}</span>
                    </td>
                    <td
                      className={`${tableHeaderEmailHistoryTable[1].rowWid} py-5 break-all pl-6 md:pl-1 align-top md:pl-6`}
                    >
                      <span>{itemAMS.to}</span>
                    </td>
                    <td
                      className={`${
                        tableHeaderEmailHistoryTable[2].rowWid
                      } py-5 break-all pl-6 md:pl-3 align-top ${'md:pl-[20px]'}`}
                    >
                      <span>
                        {
                          <Moment format="yyyy-MM-DD HH:mm">
                            {moment.utc(itemAMS.send_date ?? '')}
                          </Moment>
                        }
                      </span>
                    </td>
                    <td
                      className={`${tableHeaderEmailHistoryTable[3].rowWid} py-5 break-all pl-6 md:pl-1 align-top md:pl-6`}
                    >
                      <span>{itemAMS.subject}</span>
                    </td>
                    <td
                      className={`${tableHeaderEmailHistoryTable[4].rowWid} lg:pl-8 pl-1 md:${tableHeaderEmailHistoryTable[3].rowWid} align-top`}
                    >
                      <div className="relative w-full break-all ">
                        <div className="h-[86px]">
                          <p
                            dangerouslySetInnerHTML={{ __html: itemAMS.content }}
                            style={{ ...styleJsonPacket }}
                            className={`break-all lg:pl-2 pt-[19px] pl-6 pr-6`}
                          ></p>
                          <button
                            title="View Email"
                            onClick={(e) => {
                              e.stopPropagation()
                              onHandleClickViewDetailJobAction()
                              setCurrentEmailItemSelected(itemAMS)
                            }}
                          >
                            <img
                              src={IconViewEmailByAMS}
                              alt="Icon-Hover-Copy"
                              className="absolute top-[24px] right-0"
                            />
                          </button>
                        </div>
                      </div>
                    </td>

                    <td
                      className={`${tableHeaderEmailHistoryTable[5].rowWid} py-5 pl-8 break-all align-top`}
                    >
                      <span> {itemAMS?.status}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </InfiniteScroll>
        )
      )}

      {isOpenModalViewDetailEmail && (
        <ViewDetailContentItemDialog
          key={currentEmailItemSelected?.jobId}
          modalIsOpen={true}
          title="View Email Detail"
          handleCloseDialog={handleCloseEmailInfoDialog}
          contentHtml={currentEmailItemSelected?.content || ''}
        />
      )}
    </div>
  )
}
export default memo(EmailHistoryTable)
