export const tableHeaderEmailHistoryTable = [
  {
    styles: 'w-[7%] lg:pt-0',
    rowWid: 'w-[7%]',
    name: 'Job Id',
    label: 'job_id',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: true,
  },
  {
    styles: 'w-[16%]',
    rowWid: 'w-[16%]',
    name: 'To Email',
    label: 'to_email',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: false,
  },
  {
    styles: 'lg:pt-0 w-[13%]',
    rowWid: 'w-[13%]',
    name: 'Send Date',
    label: 'send_date',
    isFilter: true,
    isFilterDate: true,
    isOneLineRes: true,
  },
  {
    styles: 'lg:pt-0 w-[20%]',
    rowWid: 'w-[20%]',
    name: 'Subject',
    label: 'subject',
    isFilter: true,
    isFilterDate: true,
    isOneLineRes: true,
  },
  {
    styles: 'w-[33%]',
    rowWid: 'w-[33%]',
    name: 'Content',
    label: 'content',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: false,
  },
  {
    styles: 'w-[11%] lg:pt-0 pl-0',
    rowWid: 'w-[11%]',
    name: 'Status',
    label: 'Status',
    isFilter: false,
    isFilterDate: false,
    isOneLineRes: true,
  },
]