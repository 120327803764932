import React, { memo, useEffect, useState } from 'react'
import { tableHeaderTextHistoryTable } from './headers'
import Moment from 'react-moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { styleJsonPacket } from '../../../../../constants/common'
import { MESSAGES, STATUS } from '../../../../../constants/messages'
import IconHoverCopyByAMS from '../../../../../assets/images/icon-pacc-found.svg'
import { useQuery } from '@apollo/client'
import { GET_TEXT_HISTORY_QUERY } from '../../../../../queries/adminPortal'
import { notify } from '../../../../../components/toastify'
import classNames from 'classnames'
import DateFilterPicker from '../../../../developer-portal/table/activities-log/component/dateFilterPicker'
import SearchInput from '../../../../principal-account-management/components/searchInput'
import moment from 'moment'
import Loading from '../../../../../components/loading'
import ViewDetailContentItemDialog from '../../share/view-detail-content-log'

interface Props {
  setIsRefetchList: (refetchUser: boolean) => void
  isRefetchList: boolean
}

interface IHistoryTextItem {
  jobId: number
  to: string
  send_date: string
  content: string
  status: boolean
  source: string
}
interface ITextFilterItem {
  dateFrom?: string
  dateTo?: string
  skip: number
  take: number
  searchValue?: string
}

const TextHistoryTable: React.FC<Props> = () => {
  const [hasMoreTextData, setHasMoreTextData] = useState<boolean>(true)
  const [isOpenModalViewDetailEmail, setIsOpenModalViewDetailJob] = useState<boolean>(false)
  const [currentTextItemSelected, setCurrentEmailItemSelected] = useState<IHistoryTextItem>()
  const [listTextItemLog, setListTextItemLog] = useState<IHistoryTextItem[]>([])
  const [searchObject, setSearchObject] = useState<ITextFilterItem>()
  const [minDateFilter, setMinDateFilter] = useState<Date>()
  const [maxDateFilter, setMaxDateFilter] = useState<Date>()
  const [isFocusSearchJobIdOrPhone, setIsFocusSearchJobIdOrPhone] = useState<boolean>(false)
  const fetchMoreDataForTextHistory = async () => {
    try {
      const response = await fetchMoreForGetTextLog({
        variables: {
          filter: {
            ...searchObject,
            skip: listTextItemLog.length,
            take: 20,
          },
        },
      })
      const data = response.data.getTextHistory
      if (data.length > 0) {
        setHasMoreTextData(data.length === 20)
        const dataState = [...listTextItemLog, ...data]
        setTimeout(() => {
          setListTextItemLog(dataState)
        }, 1000)
      } else {
        setHasMoreTextData(false)
        return
      }
    } catch (error) {
      notify(MESSAGES.ERROR.E_TRY_AGAIN, STATUS.ERROR)
    }
  }
  const handleCloseInfoDialog = () => {
    setIsOpenModalViewDetailJob(false)
  }

  const onHandleClickViewDetailJobAction = () => {
    setIsOpenModalViewDetailJob(true)
  }

  const {
    data: getTextHistoriesFromQuery,
    loading: getTextHistoryLoading,
    refetch: getTextLogListRefetch,
    fetchMore: fetchMoreForGetTextLog,
  } = useQuery(GET_TEXT_HISTORY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: searchObject,
    },
  })

  const startDateChange = (value: Date) => {
    setMinDateFilter(value)
    const dateFrom = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateFrom, take: 20, skip: 0 }
    })
  }
  const endDateChange = (value: Date) => {
    setMaxDateFilter(value)
    const dateTo = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateTo, take: 20, skip: 0 }
    })
  }
  const handleSearchJobIdAndPhone = (value: string) => {
    setSearchObject((state) => {
      return { ...state, searchValue: value.trim(), take: 20, skip: 0 }
    })
  }
  // set data for list activities log
  useEffect(() => {
    setListTextItemLog(getTextHistoriesFromQuery?.getTextHistory)
  }, [getTextHistoriesFromQuery])
  useEffect(() => {
    if (searchObject) {
      setHasMoreTextData(true)
      getTextLogListRefetch()
    }
  }, [searchObject])
  return (
    <div>
      <div className="flex justify-between mb-3">
        <p className="pt-2 text-headline5A text-neutral-1">Text History</p>
      </div>
      <div className="flex w-full mb-5 grid grid-cols-3 gap-2">
        <DateFilterPicker setCallBackDateChange={startDateChange} maxDate={maxDateFilter} />
        <DateFilterPicker setCallBackDateChange={endDateChange} minDate={minDateFilter} />
        <div
          className={classNames('rounded-xl border-[2px]  w-full', {
            'border-neutral-3': isFocusSearchJobIdOrPhone,
            'border-neutral-5': !isFocusSearchJobIdOrPhone,
          })}
        >
          <SearchInput
            classInput="border-0 w-full"
            setIsFocus={setIsFocusSearchJobIdOrPhone}
            handleSearch={handleSearchJobIdAndPhone}
            placehoderText="Search box jobId or phone"
            isHandleSearch={false}
          />
        </div>
      </div>
      <table className="w-full text-left">
        <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
          <tr>
            {tableHeaderTextHistoryTable.map((item: any, idx) => (
              <th key={idx} className={`font-semibold py-5 pl-6 ${item.styles}`}>
                <div className={`flex`}>
                  <span className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}>{item.name}</span>
                  <div className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}></div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
      </table>
      {getTextHistoryLoading && <Loading className="relative py-6" height={30} width={30} />}

      {listTextItemLog?.length === 0 && !getTextHistoryLoading ? (
        <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
      ) : (
        Boolean(
          listTextItemLog !== null && listTextItemLog?.length > 0 && !getTextHistoryLoading,
        ) && (
          <InfiniteScroll
            dataLength={listTextItemLog?.length}
            next={fetchMoreDataForTextHistory}
            hasMore={hasMoreTextData}
            height={'max-content'}
            style={{ maxHeight: 980 }}
            loader={''}
            endMessage={''}
            className="cus-scrollbar"
          >
            {listTextItemLog?.map((itemAMS: IHistoryTextItem, index: number) => (
              <table key={index} className="cursor-pointer w-full">
                <tbody>
                  <tr className="h-[72px] w-full hover:bg-neutral-7 pt-5">
                    <td
                      className={` ${tableHeaderTextHistoryTable[0].rowWid} py-5 break-all pl-6 md:pl-1 align-top md:pl-6`}
                    >
                      <span>{itemAMS?.jobId || 'NA'}</span>
                    </td>
                    <td
                      className={`${tableHeaderTextHistoryTable[1].rowWid} py-5 break-all pl-6 md:pl-1 align-top md:pl-6`}
                    >
                      <span>{itemAMS?.to}</span>
                    </td>
                    <td
                      className={`${
                        tableHeaderTextHistoryTable[2].rowWid
                      } py-5 break-all pl-6 md:pl-3 align-top ${'md:pl-[20px]'}`}
                    >
                      <span>
                        {
                          <Moment format="yyyy-MM-DD HH:mm">
                            {moment.utc(itemAMS.send_date ?? '')}
                          </Moment>
                        }
                      </span>
                    </td>
                    <td
                      className={`${tableHeaderTextHistoryTable[3].rowWid} py-5 break-all pl-6 md:pl-1 align-top md:pl-6`}
                    >
                      <span>{itemAMS?.source}</span>
                    </td>
                    <td
                      className={`${tableHeaderTextHistoryTable[4].rowWid} lg:pl-8 pl-1 md:${tableHeaderTextHistoryTable[3].rowWid} align-top`}
                    >
                      <div className="relative w-full break-all ">
                        <div className="h-[86px]">
                          <span
                            style={{ ...styleJsonPacket }}
                            className={`break-all lg:pl-2 pt-[19px] pl-6  pr-6`}
                          >
                            {itemAMS?.content}
                          </span>
                          <button
                            title="View Text"
                            onClick={(e) => {
                              e.stopPropagation()
                              onHandleClickViewDetailJobAction()
                              setCurrentEmailItemSelected(itemAMS)
                            }}
                          >
                            <img
                              src={IconHoverCopyByAMS}
                              alt="Icon-Hover-Copy"
                              className="absolute top-[24px] right-0"
                            />
                          </button>
                        </div>
                      </div>
                    </td>

                    <td
                      className={`${tableHeaderTextHistoryTable[5].rowWid} py-5 pl-8 break-all align-top`}
                    >
                      <span> {itemAMS?.status}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </InfiniteScroll>
        )
      )}
      {isOpenModalViewDetailEmail && (
        <ViewDetailContentItemDialog
          key={currentTextItemSelected?.jobId}
          modalIsOpen={true}
          title="View Text Detail"
          handleCloseDialog={handleCloseInfoDialog}
          contentHtml={currentTextItemSelected?.content || ''}
        />
      )}
    </div>
  )
}
export default memo(TextHistoryTable)
