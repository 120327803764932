import { gql } from '@apollo/client'

export const PUSH_READ_RECORDS_ADMIN = gql`
  mutation UpdateLastRecordByAdmin($data: UpdateLastRecordAdminInput!) {
    updateLastRecordByAdmin(data: $data) {
      partyId
      lastRecordRead
    }
  }
`

export const GET_LAST_READ_RECORD_ADMIN = gql`
  query LastRecordByAdmin($data: GetLastRecordAdminInput!) {
    lastRecordByAdmin(data: $data) {
      lastRecord
      lastRecordConfirmed
      unreadRecord
    }
  }
`

export const GET_WEBHOOK_ACTIVE_STATUS = gql`
  query GetWebhookActiveStatus {
    getWebhookActiveStatus
  }
`

export const GET_RETRY_SETTING = gql`
  query GetRetrySetting {
    getRetrySetting {
      timeOut
      try1
      try2
      try3
      value1
      value2
      value3
    }
  }
`

export const SET_RETRY_SETTING = gql`
  mutation SetRetrySetting(
    $timeOut: Int!
    $try1: Int!
    $value1: Int!
    $try2: Int!
    $value2: Int!
    $try3: Int!
    $value3: Int!
  ) {
    setRetrySetting(
      data: {
        timeOut: $timeOut
        try1: $try1
        value1: $value1
        try2: $try2
        value2: $value2
        try3: $try3
        value3: $value3
      }
    )
  }
`

export const HOLD_WEBHOOK = gql`
  mutation HoldWebhook {
    holdWebhook
  }
`

export const FIRE_WEBHOOK = gql`
  mutation FireWebhook {
    fireWebhook
  }
`

export const FILTER_BY_MULTI_COLUMN = gql`
  query FilterByMultiColumnByAdmin($data: SearchAdminInput!) {
    filterByMultiColumnByAdmin(data: $data) {
      activityId
      activityType
      createdAt
      jsonPacket
      userName
      statusResponse
      partyId
      kind
      organizationName
    }
  }
`

export const FILTER_BY_GROUP = gql`
  query FilterByGroupByAdmin($data: FilterForAdminInput!) {
    filterByGroupByAdmin(data: $data) {
      key
      partyId
    }
  }
`

export const LOGOUT_ACTIVITIES = gql`
  mutation LogActivity($data: CreateActivityInput!) {
    logActivity(data: $data)
  }
`

export const GET_NEW_USER_REQUESTS = gql`
  query GetUserRequests {
    getUserRequests {
      userId
      createdAt
      firstName
      lastName
      phoneNumber
      email
      businessSponsorFirstName
      businessSponsorLastName
      businessSponsorPhoneNumber
      businessSponsorEmail
      organization {
        id
        name
        kind
      }
      gsuiteUsername
      requesterId
    }
  }
`

export const APPROVE_USER_REQUEST = gql`
  mutation CreateGsuiteUser($data: CreateGsuiteUserInput!) {
    createGsuiteUser(data: $data) {
      lastName
      phoneNumber
      email
      avatar
      gsuiteUsername
      gid
      businessSponsorFirstName
      businessSponsorLastName
      businessSponsorEmail
      businessSponsorPhoneNumber
    }
  }
`

export const REJECT_USER_REQUEST = gql`
  mutation RejectRequestAccount($data: RejectAccountInput!) {
    rejectRequestAccount(data: $data) {
      firstName
      lastName
    }
  }
`

export const REJECT_USER_REQUEST_TO_DEVELOPMENT = gql`
  mutation RejectRequestAccount($data: RejectAccountToDevelopmentInput!) {
    rejectRequestAccountToDevelopment(data: $data) {
      firstName
      lastName
    }
  }
`

export const GET_WEB_HOOK_BY_ADMIN = gql`
  query GetWebhookByAdmin($data: GetWebhookForAdminInput!) {
    getWebhookByAdmin(data: $data) {
      url
      webhookId
      username
      password
      isAutoSendWebhook
      webhookHeader {
        webhookHeaderId
        value
        key
        isChecked
      }
    }
  }
`

export const PUSH_WEB_HOOK_BY_ADMIN = gql`
  mutation PushUnreadRecordByAdmin(
    $data: PushWebhookForAdminInput!
    $pushUnreadRecordByAdminId: String!
  ) {
    pushUnreadRecordByAdmin(data: $data, id: $pushUnreadRecordByAdminId) {
      statusCode
      message
    }
  }
`

export const SAVE_URL_WEB_HOOK_BY_ADMIN = gql`
  mutation UpdateWebhookByAdmin($data: UpdateWebhookForAdminInput!, $webhookId: String!) {
    updateWebhookByAdmin(data: $data, webhookId: $webhookId) {
      webhookId
      url
    }
  }
`

export const GET_PARTY_NAME = gql`
  query GetPartyName($kind: kindType!, $limit: Int, $page: Int) {
    getPartyName(kind: $kind, limit: $limit, page: $page) {
      id
      name
      kind
    }
  }
`

export const UPDATE_CARRIER_FOR_USER_REQUEST = gql`
  mutation UpdateUserParty($data: UpdateUserCarrierInput!, $userId: String!) {
    updateUserParty(data: $data, userId: $userId) {
      firstName
      lastName
    }
  }
`

export const UPDATE_WEBHOOK_PASSWORD_BY_ADMIN = gql`
  mutation UpdateWebhookBasicAuthenticationByAdmin(
    $data: UpdateWebhookBasicAuthenticationByAdminInput!
    $webhookId: String!
  ) {
    updateWebhookBasicAuthenticationByAdmin(data: $data, webhookId: $webhookId) {
      username
      password
      webhookId
    }
  }
`
export const GET_API_KEY_BY_ADMIN = gql`
  query ApiKeysByAdmin($data: GetApikeyAdminInput!) {
    apiKeysByAdmin(data: $data) {
      apiKeyId
      name
      accessKey
      status
      createdAt
    }
  }
`
export const GENERATE_KEY_BY_ADMIN = gql`
  mutation AddApiKeyByAdmin($data: CreateApikeyAdminInput!) {
    addApiKeyByAdmin(data: $data) {
      apiKeyId
      name
      accessKey
      status
      createdAt
    }
  }
`
export const CHANGE_STATUS_API_KEY_BY_ADMIN = gql`
  mutation UpdateActiveStatusAndNameByAdmin($data: UpdateApiKeyStatusAndNameAdminInput!) {
    updateActiveStatusAndNameByAdmin(data: $data) {
      apiKeyId
      name
      status
    }
  }
`
export const DELETE_KEY_BY_ADMIN = gql`
  mutation DeleteApiKeyByAdmin($data: DeleteApikeyAdminInput!) {
    deleteApiKeyByAdmin(data: $data) {
      name
    }
  }
`
export const CHANGE_KEY_NAME_BY_ADMIN = gql`
  mutation ChangeKeyName($data: ChangeApiKeyAdminInput!) {
    changeKeyName(data: $data) {
      apiKeyId
      createdAt
      name
      accessKey
      status
    }
  }
`
export const GET_USER_LIST = gql`
  query GetUserList($organizationIds: [String], $kind: String) {
    getUserList(organizationIds: $organizationIds, kind: $kind) {
      firstName
      lastName
      userId
      phoneNumber
      email
      gsuiteUsername
      isBlocked
      requesterId
      organization {
        id
        name
        kind
      }
      role {
        name
        roleId
      }
    }
  }
`
export const GET_TOTAL_USER = gql`
  query {
    getTotalTypeUser {
      name
      count
      type
    }
  }
`

export const UPDATE_BLOCK_USER = gql`
  mutation UpdateBlockUser($isBlocked: Boolean!, $userId: String!) {
    updateBlockUser(isBlocked: $isBlocked, userId: $userId) {
      userId
      firstName
      lastName
      isProduction
    }
  }
`
export const UPDATE_BLOCK_ACCESS_USER = gql`
  mutation ModifyUserToAccess($isBlocked: Boolean!, $userId: String!) {
    modifyUserToAccess(isBlock: $isBlocked, userId: $userId) {
      userId
      firstName
      lastName
      isProduction
      isBlocked
    }
  }
`

export const UPDATE_BLOCK_USER_PRODUCTION = gql`
  mutation UpdateBlockUser($isBlocked: Boolean!, $requesterId: String!) {
    updateBlockUser(isBlocked: $isBlocked, requesterId: $requesterId) {
      userId
      firstName
      lastName
      isProduction
    }
  }
`
export const DELETE_USER = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      firstName
      lastName
      isProduction
    }
  }
`
export const DELETE_USER_PRODUCTION = gql`
  mutation DeleteUser($requesterId: String!) {
    deleteUser(requesterId: $requesterId) {
      firstName
      lastName
      isProduction
    }
  }
`
export const GET_CARRIER_INFO = gql`
  query GetPartyWithSponsorAndUsers($data: GetPartyWithSponsorAndUsersInput!) {
    getPartyWithSponsorAndUsers(data: $data) {
      partyId
      users {
        isDeleted
        isBlocked
        kind
        gid
        gsuiteUsername
        email
        phoneNumber
        lastName
        firstName
        userId
        avatar
      }
      businessSponsor {
        businessSponsorPhoneNumber
        businessSponsorEmail
        businessSponsorLastName
        businessSponsorId
        businessSponsorFirstName
      }
    }
  }
`
export const EDIT_DEVELOPER_INFO_BY_ADMIN = gql`
  mutation ModifyProfileInfoByAdmin($data: ModifyProfileInput!, $userId: String!, $roleId: String) {
    modifyProfileInfoByAdmin(data: $data, userId: $userId, roleId: $roleId) {
      userId
      lastName
      firstName
      email
      phoneNumber
    }
  }
`
export const EDIT_SPONSOR_INFO_BY_ADMIN = gql`
  mutation ModifyBusinessSponsorByAdmin(
    $data: ModifyBusinessSponsorInput!
    $businessSponsorId: String!
  ) {
    modifyBusinessSponsorByAdmin(data: $data, businessSponsorId: $businessSponsorId) {
      businessSponsorId
      partyId
      kind
      businessSponsorPhoneNumber
      businessSponsorEmail
      businessSponsorFirstName
      businessSponsorLastName
    }
  }
`
export const GET_SUGGEST_P_ACC = gql`
  query GetSuggestionPrincipalAccount($data: GetSuggestionPrincipalAccountInput!) {
    getSuggestionPrincipalAccount(data: $data) {
      principalAccountId
      principalAccountName
      principalAccountAddressLine1
      principalAccountContactName
      principalAccountPhoneNumber
    }
  }
`
export const GET_SUGGEST_PRINCIPAL = gql`
  query GetSuggestionPrincipal($data: GetSuggestionPrincipalInput!) {
    getSuggestionPrincipal(data: $data) {
      principalId
      principalName
      principalAddressLine1
      principalZipcode
    }
  }
`
export const GROUP_PRINCIPAL_ACCOUNT_TO_PRINCIPAL_ACCOUNT = gql`
  mutation GroupPrincipalAccountToPrincipalAccount(
    $principalAccountIdTo: String!
    $principalAccountIdFrom: String!
  ) {
    groupPrincipalAccountToPrincipalAccount(
      principalAccountIdTo: $principalAccountIdTo
      principalAccountIdFrom: $principalAccountIdFrom
    ) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountAddressLine2
      principalAccountCity
      principalAccountState
      principalAccountZipcode
    }
  }
`
export const GROUP_PRINCIPAL_TO_PRINCIPAL_ACCOUNT = gql`
  mutation GroupPrincipalToPrincipalAccount($principalId: String!, $principalAccountId: String!) {
    groupPrincipalToPrincipalAccount(
      principalId: $principalId
      principalAccountId: $principalAccountId
    ) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountAddressLine2
      principalAccountCity
      principalAccountState
      principalAccountZipcode
    }
  }
`
export const CREATE_AND_GROUP_PRINCIPAL_TO_PRINCIPAL_ACCOUNT = gql`
  mutation CreateAndGroupPrincipalToPrincipalAccount(
    $data: CreatePrincipalAccount!
    $principalId: String!
  ) {
    createAndGroupPrincipalToPrincipalAccount(data: $data, principalId: $principalId) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountAddressLine2
      principalAccountCity
      principalAccountState
      principalAccountZipcode
    }
  }
`
export const SEARCH_PRINCIPAL = gql`
  mutation searchPrincipalByNameAndAddress($data: GetSearchPrincipalInput!) {
    searchPrincipalByNameAndAddress(data: $data) {
      count
      data {
        recordsCount
        agencyName
        principalAccountName
        principalAddressLine1
        principalName
        principalKey
      }
    }
  }
`
export const SEARCH_PRINCIPAL_ACCOUNT = gql`
  mutation Data($data: GetSearchPrincipalAccountInput!) {
    searchPrincipalAccountByNameAndAddress(data: $data) {
      data {
        countPrincipal
        principalAccountBusinessEmail
        principalAccountName
        principalAccountId
        principalAccountAddressLine1
        principalAccountState
        principalAccountPhoneNumber
        principals {
          recordsCount
          principalAddressLine1
          principalAccountId
          agencyName
          principalName
          principalKey
        }
      }
      count
    }
  }
`
export const GET_PRINCIPAL_ACCOUNT_INFO = gql`
  mutation GetPrincipalAccount($data: GetPrincipalAccountInput!) {
    getPrincipalAccount(data: $data) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountCity
      principalAccountAddressLine2
      principalAccountState
      principalAccountZipcode
      countPrincipal
    }
  }
`
export const GET_PRINCIPAL_ACCOUNT_DETAIL = gql`
  mutation GetPrincipalsInPrincipalAccountDetail($data: GetPrincipalByPrincipalAccountInput!) {
    getPrincipalsInPrincipalAccountDetail(data: $data) {
      principalName
      agencyName
      principalKey
      principalAccountId
      principalAddressLine1
      recordsCount
    }
  }
`
export const EDIT_PRINCIPAL_DETAIL = gql`
  mutation UpdatePrincipalAccount(
    $data: UpdatePrincipalAccountInput!
    $principalAccountId: String!
  ) {
    updatePrincipalAccount(data: $data, principalAccountId: $principalAccountId) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountAddressLine2
      principalAccountCity
      principalAccountState
      principalAccountZipcode
    }
  }
`
export const GET_CLIENT_CREDENTIAL_ADMIN = gql`
  query GetClientCredentialForAdmin($data: AdminBaseInput!) {
    getClientCredentialForAdmin(data: $data) {
      audience
      Auth0Url
      clientId
      secretKey
    }
  }
`
export const CREATE_CLIENT_CREDENTIAL_ADMIN = gql`
  mutation CreateClientCredentialAdmin($data: CreateClientCredentialAdmin!) {
    createClientCredentialForAdmin(data: $data) {
      audience
      Auth0Url
      clientId
      secretKey
    }
  }
`

export const DELETE_CLIENT_CREDENTIAL_ADMIN = gql`
  mutation DeleteClientCredentialForAdmin($data: AdminBaseInput!) {
    deleteClientCredentialForAdmin(data: $data) {
      audience
      Auth0Url
      clientId
      secretKey
    }
  }
`

export const SET_AUTO_SEND_WEBHOOK = gql`
  mutation SetAutoSendWebhook(
    $webhookId: String!
    $isAutoSendWebhook: Boolean!
    $partyId: String
    $kind: String
  ) {
    setAutoSendWebhook(
      webhookId: $webhookId
      isAutoSendWebhook: $isAutoSendWebhook
      partyId: $partyId
      kind: $kind
    )
  }
`

export const SET_WEBHOOK_HEADER_BY_CARRIER = gql`
  mutation SetWebhookHeaderByCarrier($data: [SetWebhookHeaderInput!]!) {
    setWebhookHeaderByCarrier(data: $data)
  }
`

export const SET_WEBHOOK_HEADER_BY_ADMIN = gql`
  mutation SetWebhookHeaderByAdmin($data: SetWebhookHeaderByAdminInput!) {
    setWebhookHeaderByAdmin(data: $data)
  }
`

export const DELETE_WEBHOOK_HEADER_BY_CARRIER = gql`
  mutation DeleteWebhookHeaderByCarrier($webhookHeaderId: String!) {
    deleteWebhookHeaderByCarrier(webhookHeaderId: $webhookHeaderId)
  }
`

export const DELETE_WEBHOOK_HEADER_BY_ADMIN = gql`
  mutation DeleteWebhookHeaderByAdmin($webhookHeaderId: String!) {
    deleteWebhookHeaderByAdmin(webhookHeaderId: $webhookHeaderId)
  }
`

export const GET_AGENCY_BY_AMS_ADMIN = gql`
  query GetAgenciesByAMSAdmin($partyId: String!, $kind: String!) {
    getAgenciesByAMSAdmin(partyId: $partyId, kind: $kind) {
      agencyAmsId
      agencyAmsKey
      agency {
        agencyId
        agencyName
        agencyKey
      }
    }
  }
`

export const GET_AGENCIES_BY_ADMIN = gql`
  query GetAgencies($skip: Int, $take: Int, $agencyName: String!) {
    getAgenciesByAdmin(skip: $skip, take: $take, agencyName: $agencyName) {
      agencyId
      agencyKey
      agencyName
    }
  }
`

export const CREATE_AGENCY_BY_ADMIN = gql`
  mutation Mutation($data: CreateAgencyInput!) {
    createAgency(data: $data) {
      agencyAmsId
    }
  }
`

export const DELETE_AGENCY_BY_ADMIN = gql`
  mutation DeleteAgency($data: DeleteAgencyInput!) {
    deleteAgency(data: $data) {
      agencyAmsKey
    }
  }
`

export const SEARCH_AMS = gql`
  query SearchAMS($agencyManagementProviderName: String!) {
    searchAMS(agencyManagementProviderName: $agencyManagementProviderName) {
      agencyManagementProviderId
      agencyManagementProviderName
      kind
      partyId
    }
  }
`
export const MERGE_AMS = gql`
  mutation UpdateUser($data: UpdateUserInput!, $updateUserId: String!) {
    updateUser(data: $data, id: $updateUserId) {
      organizationName
    }
  }
`
export const ADD_AMS_TO_SYSTEM = gql`
  mutation CreateAMS($agencyManagementProviderName: String!) {
    createAMS(agencyManagementProviderName: $agencyManagementProviderName) {
      agencyManagementProviderId
      agencyManagementProviderName
    }
  }
`

export const GET_ALL_ROLE_BY_KIND = gql`
  query GetAllRoleByKind($kind: KindEnum) {
    getAllRoleByKind(kind: $kind) {
      name
      roleId
    }
  }
`
export const UPDATE_ROLE_FOR_USER = gql`
  mutation UpdateRoleForUser($data: UpdateRoleForUser!) {
    updateRoleForUser(data: $data) {
      role {
        name
        roleId
      }
    }
  }
`

export const GET_ALL_GA_JOB = gql`
  query getAllGaJob(
    $searchValue: String
    $applicationNumber: String
    $status: String
    $dateFrom: String
    $dateTo: String
    $page: Int
    $limit: Int
  ) {
    getAllGaJob(
      searchValue: $searchValue
      applicationNumber: $applicationNumber
      status: $status
      dateFrom: $dateFrom
      dateTo: $dateTo
      limit: $limit
      page: $page
    ) {
      items {
        jobId
        jobName
        stageDate
        stageCompleted
        status
        applicationNumber
        email
        phoneNumber
        createdAt
      }
      pageInfo {
        currentPage
        totalPages
        totalItems
        itemsPerPage
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const CANCEL_JOB_BY_ID = gql`
  mutation CancelJobById($jobId: Float!) {
    cancelJobById(jobId: $jobId)
  }
`
export const TRIGGER_JOB_BY_ID = gql`
  mutation TestRunSendEmailJob($jobId: Float!) {
    testRunSendEmailJob(jobId: $jobId)
  }
`

export const GET_ALL_EMAIL_SUBJECT_DATA = gql`
  query getAllEmailManagementConfig {
    getAllEmailManagementConfig {
      items {
        startSubject
        cancelSubject
        subEmailManagementConfig {
          subEmailManagementConfigId
          tDayConfig {
            configId
            key
            value
          }
          action
          step
          emailTemplateURL
          textTemplateURL
          sendingEmailSubject
          createdAt
          updatedAt
        }
        emailManagementConfigId
        createdAt
        updatedAt
      }
      pageInfo {
        currentPage
        totalPages
        totalItems
        itemsPerPage
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const GET_EMAIL_SUBJECT_BY_ID = gql`
  query GetEmailManagementConfigById($emailManagementConfigId: String!) {
    getEmailManagementConfigById(emailManagementConfigId: $emailManagementConfigId) {
      emailManagementConfigId
      startSubject
      cancelSubject
      subEmailManagementConfig {
        subEmailManagementConfigId
        tDayConfig {
          configId
          key
          value
        }
        action
        step
        emailTemplateURL
        textTemplateURL
        sendingEmailSubject
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_JOB_DATA = gql`
  query GetJobById($jobId: Float!) {
    getJobById(jobId: $jobId) {
      jobId
      jobName
      stageDate
      stageCompleted
      status
      applicationNumber
      jobLastRunTime
      textTemplateURL
      emailTemplateURL
      step
      action
      isLatest
      email
      phoneNumber
      errorMessage
    }
  }
`

export const UPDATE_JOB_DATA = gql`
  mutation UpdateGAJob($jobData: UpdateGoogleAnalyticsJobInput!, $jobId: Float!) {
    updateGAJob(jobData: $jobData, jobId: $jobId) {
      jobId
      jobName
      stageDate
      stageCompleted
      expiryDate
      status
      applicationNumber
      jobLastRunTime
      textTemplateURL
      emailTemplateURL
      step
      action
      isLatest
      email
      phoneNumber
      isChangedInformation
      tDayNumber
      sendingEmailSubject
      createdAt
      updatedAt
    }
  }
`

export const GET_JOB_DATA_AND_HISTORY_JOB = gql`
  query GetDetailJobById($jobId: Float!) {
    getDetailJobById(jobId: $jobId) {
      item {
        jobId
        jobName
        stageCompleted
        status
        applicationNumber
        jobLastRunTime
        textTemplateURL
        emailTemplateURL
        step
        action
        isLatest
        email
        phoneNumber
        gaJobLog {
          message
          type
        }
      }
      historyItems {
        jobId
        jobName
        stageCompleted
        status
        applicationNumber
        jobLastRunTime
        textTemplateURL
        emailTemplateURL
        step
        action
        isLatest
        email
        phoneNumber
        gaJobLog {
          message
          type
        }
      }
    }
  }
`

export const EDIT_EMAIL_SUBJECT_SETTING = gql`
  mutation updateEmailManagementConfig(
    $data: UpdateEmailManagementConfigInput!
    $emailManagementConfigId: String!
  ) {
    updateEmailManagementConfig(data: $data, emailManagementConfigId: $emailManagementConfigId) {
      emailManagementConfigId
      startSubject
      cancelSubject
    }
  }
`

export const ADD_EMAIL_SUBJECT_SETTING = gql`
  mutation createEmailManagementConfig($data: CreateEmailManagementConfigInput!) {
    createEmailManagementConfig(data: $data) {
      emailManagementConfigId
      startSubject
      cancelSubject
    }
  }
`

export const DELETE_EMAIL_SUBJECT_SETTING = gql`
  mutation deleteEmailManagementConfigById($emailManagementConfigId: String!) {
    deleteEmailManagementConfigById(emailManagementConfigId: $emailManagementConfigId)
  }
`

export const GET_CONFIGURATION_BY_TYPE = gql`
  query GetConfigurationByType($type: String!, $isActive: Boolean, $direction: String) {
    getConfigurationByType(type: $type, isActive: $isActive, direction: $direction) {
      configId
      key
      value
      isActive
      createdAt
      updatedAt
    }
  }
`

export const GET_ALL_CONFIGURATION = gql`
  query GetAllConfiguration($page: Int, $limit: Int) {
    getAllConfiguration(page: $page, limit: $limit) {
      items {
        configId
        key
        value
        type
        isActive
        isDeleted
        createdAt
      }
      pageInfo {
        currentPage
        totalPages
        totalItems
        itemsPerPage
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const UPDATE_CONFIGURATION_BY_ID = gql`
  mutation UpdateConfigurationById($configurationId: String!, $data: UpdateConfigurationInput!) {
    updateConfigurationById(configurationId: $configurationId, data: $data) {
      configId
      key
      value
      type
      createdAt
      updatedAt
    }
  }
`

export const CREATE_NEW_CONFIGURATION = gql`
  mutation CreateNewConfiguration($data: CreateConfigurationInput!) {
    createNewConfiguration(data: $data) {
      key
      value
      type
      isActive
      createdAt
      updatedAt
    }
  }
`
export const DELETE_CONFIGURATION = gql`
  mutation DeleteConfigById($configId: String!) {
    deleteConfigById(configId: $configId)
  }
`

export const GET_CHATLIO_CONVERSATION = gql`
  query getChatlioConversation($dateFrom: String, $dateTo: String, $page: Int, $limit: Int) {
    getChatlioConversation(dateFrom: $dateFrom, dateTo: $dateTo, limit: $limit, page: $page) {
      createdAt
      id
      message
      groupType
      location
      currentPage
      visitorEmail
    }
  }
`

export const GET_CHATLIO_CONVERSATION_DETAIL = gql`
  query getChatlioConversationDetail($groupId: String!) {
    getChatlioConversationDetail(groupId: $groupId) {
      payload
      type
    }
  }
`

export const GET_CHATLIO_DATA_TO_DOWNLOAD = gql`
  query getChatlioDataToDownload($dateFrom: String, $dateTo: String) {
    getChatlioDataToDownload(dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      createdAt
      visitorEmail
      message
      currentPage
      location
      textBody
      groupType
      hasFeedback
      endAt
      updatedAt
    }
  }
`
export const GET_GA_JOB_DETAIL_TO_DOWNLOAD = gql`
  query getGAJobDetailToDownload(
    $applicationNumber: String
    $searchValue: String
    $dateFrom: String
    $dateTo: String
  ) {
    getGAJobDetailToDownload(
      applicationNumber: $applicationNumber
      searchValue: $searchValue
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      jobId
      jobName
      stageDate
      jobLastRunTime
      stageCompleted
      expiryDate
      status
      applicationNumber
      email
      phoneNumber
      createdAt
      sendingEmailSubject
      emailTemplateURL
      textTemplateURL
      gaJobLog {
        message
      }
      messageMail {
        subject
        receiver
        metaData
      }
    }
  }
`

export const GET_TEXT_HISTORY_QUERY = gql`
  query getTextHistory($filter: JobLogTableFilter!) {
    getTextHistory(filter: $filter) {
      jobId
      to
      send_date
      content
      status
      source
    }
  }
`

export const GET_EMAIL_HISTORY_QUERY = gql`
  query getEmailHistory($filter: JobLogTableFilter!) {
    getEmailHistory(filter: $filter) {
      jobId
      to
      send_date
      content
      status
      subject
    }
  }
`

export const GET_MESSAGE_MAILS = gql`
  query GetMessageMails(
    $searchValue: String
    $dateTo: String
    $dateFrom: String
    $skip: Int
    $take: Int
  ) {
    getMessageMails(
      searchValue: $searchValue
      dateTo: $dateTo
      dateFrom: $dateFrom
      skip: $skip
      take: $take
    ) {
      messageId
      applicationNumber
      carrierBondNumber
      content
      isProcessed
      # metaData
      # received_date
      receiver
      subject
      createdAt
    }
  }
`
