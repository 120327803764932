/* eslint-disable react/jsx-key */
import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import iconPen from '../../../../assets/images/icon-pen-bold.svg'
import iconReset from '../../../../assets/images/reset-bold.svg'
import BaseDialog from '../../../../components/dialog'
import ButtonBase from '../../../../components/partials/button'
import InputBase from '../../../../components/partials/inputBase'
import { GET_JOB_DATA_AND_HISTORY_JOB, UPDATE_JOB_DATA } from '../../../../queries/adminPortal'
import { JobDetail } from './interface'
import { notify } from '../../../../components/toastify'
import { MESSAGES, STATUS } from '../../../../constants/messages'

interface PropsAction {
  modalIsOpen?: boolean
  hiddenBlock?: string
  handleCloseDialog?: (val: boolean) => void
  reloadData?: (jobId: string) => void
  jobData: any
}

// interface JobInformation {
//   label: string
//   value: any
// }

const styleDetailJobItem = {
  content: {
    width: '90%',
    maxWidth: '800px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
    background: '#fdfdfd',
  },
}

// const camelCaseToWords = (s: string): string => {
//   if (s === 'textTemplateURL') {
//     return 'Text Template URL'
//   } else if (s === 'emailTemplateURL') {
//     return 'Email Template URL'
//   }
//   const result = s.replace(/([A-Z])/g, ' $1')
//   return result.charAt(0).toUpperCase() + result.slice(1)
// }

const DetailItemDialog: React.FunctionComponent<PropsAction> = ({
  jobData,
  modalIsOpen,
  handleCloseDialog = () => null,
}) => {
  const [itemHistory, setItemHistory] = useState<any[]>([])
  // const [listJobInformation, setListJobInformation] = useState<JobInformation[]>([])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [jobDetail, setJobDetail] = useState<JobDetail>({})
  const [jobDetailUpdated, setJobDetailUpdated] = useState<JobDetail>({})

  const { data: getJobDataAndHistory, refetch: reloadData } = useQuery(
    GET_JOB_DATA_AND_HISTORY_JOB,
    {
      fetchPolicy: 'no-cache',
      variables: {
        jobId: jobData?.jobId,
      },
      skip: !jobData,
    },
  )

  const [updateGAJob] = useMutation(UPDATE_JOB_DATA)

  const classInfoKey = 'text-neutral-3 text-body2 font-semibold leading-6 min-w-[160px]'
  const classInfoVal = 'text-neutral-3 text-body1 min-w-[460px] break-words'

  useEffect(() => {
    if (getJobDataAndHistory) {
      const dataDetail = getJobDataAndHistory.getDetailJobById.item
      const dataHistory = getJobDataAndHistory.getDetailJobById.historyItems
      // const inform: JobInformation[] = []
      setJobDetail(dataDetail)
      // setJobDetailUpdated(dataDetail)
      // for (const [key, value] of Object.entries(dataDetail)) {
      //   if (!value) continue
      //   if (key === 'gaJobLog' && isArray(value) && value.length > 0) {
      //     // const label = value[0].type === 'ERROR' ? 'Error Message' : 'Message'
      //     inform.push({ label: 'Message', value: value[value.length - 1].message })
      //   } else {
      //     inform.push({ label: camelCaseToWords(key), value })
      //   }
      // }
      setItemHistory(dataHistory)
      // setListJobInformation(inform)
    }
  }, [getJobDataAndHistory])

  const onChangeJobInformation = (key: keyof JobDetail, e: any) => {
    const temp = { ...jobDetailUpdated }

    temp[key] = e?.target?.value ?? e

    setJobDetailUpdated(temp)
  }

  const handleSaveJobChanges = async () => {
    const response = await updateGAJob({
      variables: {
        jobId: jobDetail.jobId,
        jobData: jobDetailUpdated,
      },
    })

    if (response.errors) {
      notify(MESSAGES.ERROR.E_CHANGE_JOB_INFORMATION, STATUS.ERROR)
    }

    notify(MESSAGES.SUCCESS.S_UPDATE_JOB_INFORMATION, STATUS.SUCCESS)
    setIsEditMode(false)
    reloadData({ jobId: jobDetail.jobId })
  }

  const onEditButton = () => {
    setJobDetailUpdated({})
    setIsEditMode(!isEditMode)
  }

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={styleDetailJobItem}>
      <div className="w-[758px] p-1 pr-0">
        <div className="flex justify-between">
          <p className="mb-2 text-headline4">Detail Job Information</p>
          <ButtonBase
            className="justify-around w-[89px] h-[40px] px-3 border-2 border-neutral-6 text-neutral-4 bg-neutral-8 hover:bg-neutral-7 hover:text-neutral-3"
            onClick={() => onEditButton()}
          >
            {!isEditMode ? (
              <div className="flex justify-between">
                <img src={iconPen} alt="iconPen" width={20} height={20} />
                <p>Edit</p>
              </div>
            ) : (
              <div className="flex justify-between">
                <img src={iconReset} alt="iconReset" width={20} height={20} />
                <p>Reset</p>
              </div>
            )}
          </ButtonBase>
        </div>
        <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
        <div className="flex-row h-[300px] overflow-y-scroll">
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Job ID</p>
            </div>
            <div className="w-3/4">
              <p className={classInfoVal}>{jobDetail?.jobId?.toString()}</p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Job Name</p>
            </div>
            <div className="w-3/4">
              <p className={classInfoVal}>{jobDetail?.jobName?.toString()}</p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Stage Completed</p>
            </div>
            <div className="w-3/4">
              <p className={classInfoVal}>{jobDetail?.stageCompleted?.toString()}</p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Email</p>
            </div>
            <div className="w-3/4">
              {isEditMode ? (
                <InputBase
                  placeholder="Email address"
                  type="email"
                  className="w-[100%] h-[90%]"
                  defaultValue={jobDetail.email ?? ''}
                  handleChange={(e) => onChangeJobInformation('email', e)}
                />
              ) : (
                <p className={classInfoVal}>{jobDetail?.email?.toString()}</p>
              )}
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Phone Number</p>
            </div>
            <div className="w-3/4">
              {isEditMode ? (
                <InputBase
                  placeholder="Phone Number"
                  type="text"
                  pattern="\d*"
                  className="w-[100%] h-[90%]"
                  defaultValue={jobDetail?.phoneNumber ?? ''}
                  handleChange={(e) => onChangeJobInformation('phoneNumber', e)}
                />
              ) : (
                <p className={classInfoVal}>{jobDetail?.phoneNumber?.toString()}</p>
              )}
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Status</p>
            </div>
            <div className="w-3/4">
              <p className={classInfoVal}>{jobDetail?.status?.toString()}</p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Application Number</p>
            </div>
            <div className="w-3/4">
              {isEditMode ? (
                <InputBase
                  placeholder="Application Number"
                  type="text"
                  className="w-[100%] h-[90%] z-0"
                  defaultValue={jobDetail?.applicationNumber ?? ''}
                  handleChange={(e) => onChangeJobInformation('applicationNumber', e)}
                />
              ) : (
                <p className={classInfoVal}>{jobDetail?.applicationNumber?.toString()}</p>
              )}
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Step</p>
            </div>
            <div className="w-3/4">
              <p className={classInfoVal}>{jobDetail?.step?.toString()}</p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Action</p>
            </div>
            <div className="w-3/4">
              <p className={classInfoVal}>{jobDetail?.action?.toString()}</p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Text Template URL</p>
            </div>
            <div className="w-3/4">
              {isEditMode ? (
                <InputBase
                  placeholder="Text Template URL"
                  type="text"
                  className="w-[100%] h-[90%] z-0"
                  defaultValue={jobDetail?.textTemplateURL ?? ''}
                  handleChange={(e) => onChangeJobInformation('textTemplateURL', e)}
                />
              ) : (
                <p className={classInfoVal}>{jobDetail?.textTemplateURL?.toString()}</p>
              )}
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Email Template URL</p>
            </div>
            <div className="w-3/4">
              {isEditMode ? (
                <InputBase
                  placeholder="Email Template URL"
                  type="text"
                  className="w-[100%] h-[90%] z-0"
                  defaultValue={jobDetail?.emailTemplateURL ?? ''}
                  handleChange={(e) => onChangeJobInformation('emailTemplateURL', e)}
                />
              ) : (
                <p className={classInfoVal}>{jobDetail?.emailTemplateURL?.toString()}</p>
              )}
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/4">
              <p className={classInfoKey}>Message</p>
            </div>
            <div className="w-3/4">
              <p className={classInfoVal}>
                {jobDetail?.gaJobLog?.[0]?.message.toString() || 'No message available'}
              </p>
            </div>
          </div>
          {/* {listJobInformation.map((item) => (
            <div className="flex mb-4">
              <div className="w-1/4">
                <p className={classInfoKey}>{item.label}</p>
              </div>
              <div className="w-3/4">
                {isBoolean(item.value) ? (
                  <p className={classInfoVal}>{item.value.toString()}</p>
                ) : isValidHttpUrl(item.value) ? (
                  <a href={item.value} className={classInfoVal}>
                    {item.value}
                  </a>
                ) : isEditMode ? (
                  <InputBase
                    placeholder="000"
                    type="text"
                    pattern="\d*"
                    maxLength={3}
                    className="w-[100%] z-0"
                    value={item.value}
                    // handleChange={(e) => handleChangeMillisecond(e)}
                  />
                ) : (
                  <p className={classInfoVal}>{item.value}</p>
                )}
              </div>
            </div>
          ))} */}
        </div>
        <p className="mb-2 mt-3 text-headline4">Job History</p>
        <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
        {itemHistory.length < 1 ? (
          <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
        ) : (
          <div className="h-[100px] overflow-y-scroll">
            <table className="w-full overflow-scroll text-left">
              <thead>
                <tr>
                  <th className="px-4 whitespace-nowrap">Job Id</th>
                  <th className="px-4 whitespace-nowrap">Step</th>
                  <th className="px-4 whitespace-nowrap">Action</th>
                  <th className="px-4 whitespace-nowrap">Status</th>
                  <th className="px-4 whitespace-nowrap">Stage Date</th>
                  <th className="px-4 whitespace-nowrap">Email Template URL</th>
                  <th className="px-4 whitespace-nowrap">Text Template URL</th>
                </tr>
              </thead>
              <tbody>
                {itemHistory.map((job) => (
                  <tr>
                    <td className="px-4">{job.jobId}</td>
                    <td className="px-4">{job.step}</td>
                    <td className="px-4">{job.action}</td>
                    <td className="px-4">{job.status}</td>
                    {/* <td className="px-4">{job.stageDate}</td> */}
                    <td className="px-4">
                      {job.stageDate && <Moment format="MM/DD/YYYY">{job.stageDate}</Moment>}
                    </td>
                    <td className="px-4 w-full max-w-40">{job.emailTemplateURL}</td>
                    <td className="px-4 w-full max-w-40">{job.textTemplateURL}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <hr className="mt-4 mb-2" />
        <div className={`flex ${isEditMode ? `justify-between` : `justify-end`}`}>
          {isEditMode ? (
            <ButtonBase
              type="submit"
              className="w-[150px] my-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
              onClick={() => {
                handleSaveJobChanges()
              }}
              bgDisabled="bg-primary-shade3"
            >
              Save
            </ButtonBase>
          ) : null}
          <ButtonBase
            type="submit"
            className="w-[150px] my-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
            onClick={() => {
              handleCloseDialog(false)
            }}
            bgDisabled="bg-primary-shade3"
          >
            Close
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default DetailItemDialog
