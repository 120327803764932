import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Tabs, { TabsType } from '../../components/partials/tabs'
import SystemEmailJobManagement from './system-job'
import SentEmailTextHistory from './sent-email-text-history'
import ReceivedEmailTextHistory from './received-email-text-history'
const EmailSystemManagement = () => {
  const tabs: TabsType = [
    {
      label: 'System Job Management',
      index: 1,
      Component: SystemEmailJobManagement,
    },
    {
      label: 'Sent: Email & Text',
      index: 2,
      Component: SentEmailTextHistory,
    },
    {
      label: 'Received: Email & Text',
      index: 3,
      Component: ReceivedEmailTextHistory,
    },
  ]

  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)

  return (
    <div>
      <Tabs
        needResponsive={true}
        selectedTab={selectedTab}
        carrier={location.state}
        pageName=""
        onClick={setSelectedTab}
        tabs={tabs}
      />
    </div>
  )
}

export default EmailSystemManagement
