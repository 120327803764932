/* eslint-disable react/jsx-key */
import React, {  } from 'react'
import BaseDialog from '../../../../components/dialog'
import ButtonBase from '../../../../components/partials/button'

interface PropsAction {
  modalIsOpen?: boolean
  title: string
  handleCloseDialog?: (val: boolean) => void
  contentHtml: any
}

// interface JobInformation {
//   label: string
//   value: any
// }

const styleDetailJobItem = {
  content: {
    width: '90%',
    
    maxWidth: '800px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
    background: '#fdfdfd',
  },
}

const ViewDetailContentItemDialog: React.FunctionComponent<PropsAction> = ({
  contentHtml: emailHtml,
  modalIsOpen,
  title,
  handleCloseDialog = () => null,
}) => {

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={styleDetailJobItem}>
      <div className="w-[758px] p-1 pr-0">
        <div className="flex justify-between">
          <p className="mb-2 text-headline4">{title}</p>
        </div>
        <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
        <div className="flex-row max-h-[600px] overflow-y-scroll">
          <p
              dangerouslySetInnerHTML={{ __html: emailHtml }}
              className={`break-all lg:pl-2 pt-[19px] pl-6 pr-6`}
            >
            </p>
        </div>
        <ButtonBase
            type="submit"
            className="w-[150px] my-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
            onClick={() => {
              handleCloseDialog(false)
            }}
            bgDisabled="bg-primary-shade3"
          >
            Close
          </ButtonBase>
      </div>
    </BaseDialog>
  )
}

export default ViewDetailContentItemDialog
