import React, { useState } from 'react'
import ReceivedEmailHistoryTable from '../components/received-email-text-history/email-history-table'

const ReceivedEmailTextHistory = () => {

  const [isRefetchList, setIsRefetchList] = useState(true)
  return (
    <div className="px-20 py-10 md:px-10">
      <ReceivedEmailHistoryTable  isRefetchList={isRefetchList} setIsRefetchList={setIsRefetchList}></ReceivedEmailHistoryTable>
      <hr className="my-10 border-t-neutral-6" />
    </div>
  )
}

export default ReceivedEmailTextHistory